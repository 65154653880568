<template>
<CModalExtended
  :title="$t('label.nueva')+' '+$t('label.portActivity')"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
>
<loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12" lg="12" xl="12">
        <CCard class="card-simple">
          <CCardBody >
            <CRow  class="mb-4">
              <CCol sm="12" lg="4" xl="4" class="text-center">
                  <div class="preview">    
                    <picture-input
                      v-if="modalActive"
                      ref="imageInput"
                      width="180"
                      height="180"
                      accept="image/jpeg,image/png"
                      size="10"
                      :custom-strings="imgInputTexts"
                      @change="handleFileUpload"
                      :imgSrc="imageRoute"
                    >
                    </picture-input>
                  </div>
              </CCol>
              <CCol sm="12" lg="8" xl="8">
                <CRow>
                    <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CInput
                      v-model.trim="$v.actividadPortuaria.PortActivityAbbrev.$model"
                      :placeholder="$t('label.acronym')"
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      v-uppercase
                      size="sm"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.PortActivityAbbrev)"
                      :is-valid="hasError($v.actividadPortuaria.PortActivityAbbrev)"
                      :label="$t('label.acronym')"
                      addLabelClasses="required text-right"
                      maxlength="6"
                    />
                  </CCol>
                  <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CInput
                      v-model.trim="$v.actividadPortuaria.PortActivityNameEs.$model"
                      :placeholder="$t('label.portActivityName')+ ' (ES)'"                                         
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      v-uppercase
                      size="sm"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.PortActivityNameEs)"
                      :is-valid="hasError($v.actividadPortuaria.PortActivityNameEs)"
                      :label="$t('label.name')+' (ES)'"
                      addLabelClasses="required text-right"
                      maxlength="50"
                    />
                  </CCol>
                  <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CInput
                      v-model.trim="$v.actividadPortuaria.PortActivityNameEn.$model"
                      :placeholder="$t('label.portActivityName')+ ' (EN)'"                                         
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      v-uppercase
                      size="sm"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.PortActivityNameEn)"
                      :is-valid="hasError($v.actividadPortuaria.PortActivityNameEn)"
                      :label="$t('label.name')+' (EN)'"
                      addLabelClasses="required text-right"
                      maxlength="50"
                    />
                  </CCol>
                  <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CInput
                      v-model.trim="$v.actividadPortuaria.ClientNameEs.$model"
                      :placeholder="$t('label.name')+' '+$t('label.IdentificationClient')+' (ES)'"                                       
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      v-uppercase
                      size="sm"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.ClientNameEs)"
                      :is-valid="hasError($v.actividadPortuaria.ClientNameEs)"
                      :label="$t('label.IdentificationClient')+' (ES)'"
                      addLabelClasses="required text-right"
                      maxlength="50"
                    />
                  </CCol>
                  <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CInput
                      v-model.trim="$v.actividadPortuaria.ClientNameEn.$model"
                      :placeholder="$t('label.name')+' '+$t('label.IdentificationClient')+' (EN)'"                                         
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      v-uppercase
                      size="sm"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.ClientNameEn)"
                      :is-valid="hasError($v.actividadPortuaria.ClientNameEn)"
                      :label="$t('label.IdentificationClient')+' (EN)'"
                      addLabelClasses="required text-right"
                      maxlength="50"
                    />
                  </CCol>
                  <CCol sm="12" class="pl-0">
                    <CInput
                      type="color"
                      size="sm"
                      :label="$t('label.color')"
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      addLabelClasses="text-right"
                      @blur="$v.actividadPortuaria.Color.$touch()"
                      :is-valid="hasError($v.actividadPortuaria.Color)"
                      v-model.trim="$v.actividadPortuaria.Color.$model" 
                    />
                  </CCol>
                </CRow>
              </CCol>
             
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
  <template #footer>
    <CButton
      shape="square"
      color="add"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="submitFile"
    >
      <div v-if="!isSubmit">
        <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept') }}</span>
      </div>
      <div v-if="isSubmit">
        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ml-1">{{ $t('button.accept') }}</span>
      </div>
    </CButton>
    <CButton
      shape="square"
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel') }}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import ActividadValidations from '@/_validations/actividad-portuaria/ActividadFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import ActividadPortuariaMixin from '@/_mixins/actividad-portuaria';
import ModalMixin from '@/_mixins/modal';
import PictureInput from '@/components/picture-input';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    actividadPortuaria: {
      PortActivityNameEs: '',
      PortActivityNameEn: '',
      ClientNameEs:'',
      ClientNameEn:'',
      PortActivityAbbrev: '',
      PortActivityRoute: '',
      Color: '',
    },
    loadingOverlay: false,
    imageRoute: '/img/uploadPhotoEs.svg',
  }
}

//Methods
function submitFile() {

 
  try {
    this.$v.$touch();
    if (this.$v.$error) {
        throw this.$t('label.errorsPleaseCheck');
    }

    this.isSubmit = true;
    this.loadingOverlay = true;

    if(this.actividadPortuaria.PortActivityRoute!=''){

          this.$http.file('PortActivity-insert', this.actividadPortuaria.PortActivityRoute)
          .then((response) => {
                 this.actividadPortuaria.PortActivityRoute = response.data.data.files[0].path;
                 this.submit();       
            }).catch((err) => {
              this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
              });
              this.isSubmit = false;
              this.loadingOverlay = false;
            })
  
    }else{
              this.submit();    
    }

  
  } catch (e) {
 
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error"
        });
  }
}


function submit() {
  this.isSubmit = true;
  this.loadingOverlay = true;
  let PortActivityJson = {
      PortActivityNameEs: this.actividadPortuaria.PortActivityNameEs,
      PortActivityNameEn: this.actividadPortuaria.PortActivityNameEn,
      ClientNameEs:this.actividadPortuaria.ClientNameEs,
      ClientNameEn:this.actividadPortuaria.ClientNameEn,
      PortActivityAbbrev: this.actividadPortuaria.PortActivityAbbrev,
      PortActivityRoute: this.actividadPortuaria.PortActivityRoute,
      PortActivityColor: this.actividadPortuaria.Color,
  };

  this.$http.post('PortActivity-insert', PortActivityJson, { root: 'PortActivityJson'} )
  .then(response => {
    this.$emit('submited');
    this.modalActive = false;
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.resetForm();
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmit = false;
    this.loadingOverlay = false;
  });
}


function toggle(newVal) {
  if(!newVal)
    this.resetForm();

  let _lang = this.$i18n.locale;
  this.imageRoute = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';

  this.modalActive = newVal;
}
function resetForm() {
  this.actividadPortuaria.PortActivityNameEs = '';
  this.actividadPortuaria.PortActivityNameEn = '';
  this.actividadPortuaria.ClientNameEs = '';
  this.actividadPortuaria.ClientNameEn = '';
  this.actividadPortuaria.PortActivityAbbrev = '';
  this.actividadPortuaria.PortActivityRoute = '';
  this.actividadPortuaria.Color = '';
  this.$v.$reset();
}
function handleFileUpload(image) {
  this.actividadPortuaria.PortActivityRoute = this.$refs.imageInput.file;
}

export default {
  name: 'modal-create-port-activity',
  mixins: [ActividadPortuariaMixin, ModalMixin],
  props: {
    modal: Boolean
  },
  components: {
    PictureInput
  },
  data,
  validations: ActividadValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    handleFileUpload,
    submitFile
  },
  computed: {  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    }
  }
}
</script>

<style scoped>
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}

.preview {
  height: 15em;
}
</style>